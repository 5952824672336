@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .bannerIcon {
        @apply w-16 h-16 bg-amber-800 bg-opacity-25 text-white text-xl inline-flex
        items-center justify-center rounded-lg cursor-pointer hover:bg-opacity-50
        hover:text-designColor
    }

    .section {
        @apply w-full h-auto pb-16 pt-16 border-b-2 border-b-amber-500 font-bodyFont
    }

    .resumeLi {
        @apply w-full h-20 flex items-center justify-center bg-gray-900 cursor-pointer hover:bg-opacity-40 border-[1px] text-amber-200
    }
    .bgOpacity {
        @apply bg-white bg-opacity-40
    }
    .contactInput {
        @apply w-full h-12 rounded-lg border-b-[1px] bg-bodyColor px-4 text-white active:outline-none focus-visible:outline-designColor outline-none
        focus-visible:border-b-transparent duration-300
    }
    .contactTextArea {
        @apply w-full rounded-lg border-b-[1px] bg-bodyColor px-4 py-2 text-white active:outline-none focus-visible:outline-designColor outline-none
        focus-visible:border-b-transparent duration-300 resize-none
    }
}
.active {
    color: white;
}